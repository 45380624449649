@import "../../styles/variables.scss";

.mui-nav-con {
  display: flex;
  align-items: center;
  max-width: 100%;
  padding: 3px 2rem;
  justify-content: space-between;
  background-color: white;
  transition: all 0.2s ease-in;

  &:hover {
    color: black;
    border-bottom: 1px solid rgb(194, 193, 193);
  }

  .nav-label-cls {
    cursor: pointer;
    font-size: 13px;

    &:hover {
      color: $primary;
    }
  }

  .active-label {
    color: $primary;
    border-bottom: 3px solid $primary;
  }
}

.side-menu-con {
  min-height: 100vh;
  border-right: 1px solid #e0e0e0;
  background-color: white;
  position: absolute;
  .side-menu-header {
    display: flex;
    justify-content: center;
    padding: 1rem;

    @media (max-width: 1415px) {
      justify-content: flex-start;
    }

    .logo-img {
      width: 70%;
      height: auto;
      cursor: pointer;
    }
  }

  .menu-items {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 15px;

    .menu-item {
      align-items: center;
      padding: 0.2rem 1rem;
      border-radius: 5px;
      transition: background-color 0.2s ease-in;

      &.active-list {
        background-color: rgba(24, 119, 242, 0.08);
        border-left: 4px solid $primary;
        color: $primary;

        &:hover {
          background-color: rgba(24, 119, 242, 0.1);
        }
      }

      &:hover {
        background-color: #f5f5f5;
        cursor: pointer;
      }

      .menu-name {
        font-size: 16px;
        font-weight: 500;
      }

      .MuiSvgIcon-root {
        margin-left: auto;
      }
    }
  }
  .sub-menu-links-con {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    position: relative; /* Ensure it stays below the parent */
    padding: 0;
    margin-top: 0.5rem; /* Add some spacing below the parent */

    .sub-menu-item {
      padding: 0.5rem 2rem;
      border-radius: 4px;
      transition: background-color 0.2s ease-in;

      &:hover {
        background-color: rgba(24, 119, 242, 0.08);
        color: $primary;
        cursor: pointer;
      }

      &.active-list {
        background-color: rgba(24, 119, 242, 0.08);
        border-left: 4px solid $primary;
        color: $primary;
      }
    }
  }
}
.no-hover {
  align-items: center;
  padding: 0.2rem 1rem;
  border-radius: 5px;
  transition: background-color 0.2s ease-in;
  :hover {
    cursor: pointer;
  }
}
