.dashboard-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.dashboard-tile {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px,
    rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  text-align: center;
  padding: 30px 20px;
}

.dashboard-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.dashboard-label {
  color: #555;
}

.dashboard-value {
  font-weight: bold;
  margin: 8px 0;
  color: #333;
}

.stats-text-con {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.store-card {
  border: 1px solid #f8f8f8;
  background: #fafafae2;
  border-radius: 5px;
  padding: 15px 30px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
