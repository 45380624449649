$all-breakpoints: (
  "small": (
    max-width: 1024px,
  ),
  "large": (
    min-width: 1025px,
  ),
  "x-large": (
    min-width: 3000px,
  ),
) !default;

/// Mixin to manage responsive breakpoints

@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($all-breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media #{inspect(map-get($all-breakpoints, $breakpoint))} {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "No value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints: #{map-keys($breakpoints)}.";
  }
}
