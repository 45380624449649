@import "../../styles/responsive.scss";
@import "../../styles/variables.scss";

.login-container {
  display: grid;
  height: 100vh;
  grid-template-columns: 1fr 1fr;
  .left {
    .login-form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      grid-gap: 20px;
      width: 420px;
      margin: auto;
      margin-top: 200px;
      @include respond-to(small) {
        width: auto;
      }
      .title {
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        text-align: center;
        line-height: 40px;
        letter-spacing: 0.1px;
        color: #102f77;
        margin-bottom: 20px;
      }
      .loginButton {
        margin-top: 10px;
      }
      .img {
        width: min-content;
      }
    }
  }
  .right {
    background-color: #102f77;
    background-image: url("../../resources/images/login.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
