.media-con {
  padding: 0px 2rem;
  @media (min-width: 3000px) {
    padding: 3rem 3rem;
  }
}
.addMediaCon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  .addMedia {
    font-size: x-large;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  .previewCon {
    background-color: rgba(128, 128, 128, 0.373);
    width: 500px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 20px;
    position: relative;
    border: 1px solid rgba(231, 229, 229, 0.307);
    p {
      font-size: 25px;
    }
  }
  .white-bg {
    background-color: white;
    border-radius: 0;
  }
}
.modal-preview {
  width: min-content;
  // height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin: auto;
}
.preview-img-size {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
}
.preview-tv-con {
  display: grid;
  grid-gap: 20px;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 30px;
}
.preview-video-size {
  height: 100%;
  width: 100%;
  object-fit: contain;
  top: 0;
  left: 0;
}

.header {
  text-align: center;
  text-transform: uppercase;
}
.vertical-television-screen {
  width: 50%;
  aspect-ratio: 9 / 16; /* Ensures a 16:9 aspect ratio */
  background: #484747; /* Black screen for the television */
  border: 8px solid #000000; /* Television border */
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
  overflow: hidden;
}
.television-screen {
  width: 90%;
  aspect-ratio: 16 / 9; /* Ensures a 16:9 aspect ratio */
  background: #484747; /* Black screen for the television */
  border: 8px solid #000000; /* Television border */
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
  overflow: hidden;
}

.preview-video-size {
  width: 100%;
  height: 100%;
}

.rotated-90 {
  transform: rotate(90deg);
}

.rotated-270 {
  transform: rotate(-90deg);
}
