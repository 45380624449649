@import "./responsive.scss";
@import "./variables.scss";
@font-face {
  font-family: "Lato-Regular";
  src: url("../resources/fonts/Lato-Regular.ttf") format("truetype");
}

body {
  font-family: "Lato-Regular";
  letter-spacing: 0.5px;
  font-weight: bold;
  font-size: small;
  margin: 0px;
  padding: 0px;
}
.error-msg {
  color: red;
  margin-top: 10px;
  display: inline-block;
}
.si-hide-mobile {
  @include respond-to("small") {
    display: none !important;
  }
}
.si-hide-web {
  display: none !important;
  @include respond-to("small") {
    display: inline-block !important;
  }
}
.page-body {
  padding: 10px 30px;
  @include respond-to("small") {
    padding: 10px;
  }

  .tab-name {
    font-size: 14px;
    font-weight: 500;
    color: $primary;
    margin: 0px;
    @include respond-to("small") {
      font-size: 14px;
    }
  }
  .section-name {
    color: #001a53;
    font-size: 23px;
    margin: 0px;
    @include respond-to("small") {
      font-size: 15px;
    }
  }
  .form-con {
    background-color: white;
    margin: auto;
    margin-top: 1rem;
    padding: 10px 0px 10px 20px;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0px 0px 4px 0px #00000026;
    overflow: auto;
    // max-height: 100vh;
    @include respond-to("small") {
      width: 100%;
      padding: 10px;
    }
    .form-header {
      font-size: 17px;
      margin-top: 0px;
      color: $primary;
      margin-bottom: 8px;
    }
  }
}
.s-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.1px;
  color: #373f41;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.css-1s2u09g-control {
  padding: 0.5em !important;
}
.MuiGrid-container {
  justify-content: flex-start;
}

::-webkit-scrollbar {
  width: 2px;
  height: 10px;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: $bg-main;
  border-radius: 10px;
}
