/** @format */
@import "../../styles/responsive.scss";
.header-container {
  background: #ffffff;
  border: 1px solid #e1e7ff;
  box-sizing: border-box;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
  overflow: hidden;
  .logo {
    width: 5rem;
    height: 5rem;
    padding-left: 1rem;
    @include respond-to("small") {
      width: 5rem;
      height: 5em;
      padding-left: 0.5rem;
    }
  }
  .menu {
    display: flex;
  }
  .endLoginContainer {
    display: flex;
    align-items: center;
    .notification-icon {
      margin-right: 1rem;
      .main-notification-icon {
        transform: scale(1.3);
        cursor: pointer;
      }
    }
  }
}
.logo-con {
  display: flex;
  align-items: center;
}
.r-c {
  display: flex;
  align-items: center;
  p {
    font-size: 25px;
    font-weight: 600;
    color: #151d48;
    margin-left: 15px;
  }
}
