.integration-con {
  padding: 0px 2rem;
}
.addIntegrationCon {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  margin-top: 2rem;
  .addIntegration {
    font-size: x-large;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  .previewCon {
    background-color: rgba(171, 169, 169, 0.373);
    width: 500px;
    border: 1px solid rgba(171, 169, 169, 0.119);

    height: 250px;
    display: flex;
    margin-top: 2rem;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
    p {
      font-size: 25px;
    }
  }
  .background-img {
    padding: 0px;
    position: relative;
    display: inline-block;
    img {
      width: inherit;
      height: inherit;
    }
  }
  .mui-table-con {
    max-height: 480px;
    overflow-y: scroll;
    .table {
      border: 1px solid rgba(224, 224, 224, 1);

      .tableCell {
        padding: 1px !important;
      }
    }
  }
}
.item-con {
  padding: 5px;
  position: absolute;
  min-width: 120px;
  border-radius: 3px;
  background-color: white;
  justify-content: space-between;
  display: flex;
  P {
    font-size: 15px !important;
    text-align: center;
    font-weight: bolder;
    margin: 2px;
    color: green;
  }
  .price {
    color: rgb(20, 79, 20);
  }
}
.card-con {
  background: #ebecf04f;
  border-radius: 14px;
  bottom: 0;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04);
  height: 150px;
  transition: all 0.3s ease-out;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.card-con:hover {
  transform: scale(1.05);
}
.active-card {
  background-color: aliceblue;
}
