.spec-con {
  padding: 0px 2rem;
  @media (min-width: 3000px) {
    padding: 3rem 3rem;
  }
}
.addStoreCon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .addMedia {
    font-size: x-large;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  .preview-img {
    width: 100%;
    height: 100%;
  }
}
.screen-card {
  width: 98%;
  min-height: 400px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 5px;
  border-radius: 10px;
  background-color: aliceblue;

  .resolution-con {
    display: flex;
    width: 95%;
    align-items: center;
    justify-content: space-between;
  }
}
.vertical-img {
  height: 150px;
  width: 100px;
}
.preview-screen-con {
  background-color: rgba(216, 214, 214, 0.418);
  height: 150px;
  width: 200px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.horizontal-img {
  height: 150px;
  width: 200px;
}
.modal-preview-spec {
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin: 5px;
  // border-radius: 20px;
}
.spec-preview-main-con {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
