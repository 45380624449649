.playlist-con {
  padding: 0px 2rem;
  @media (min-width: 3000px) {
    padding: 3rem 3rem;
  }
}
.addPlaylistCon {
  display: flex;
  flex-direction: column;

  .addPlaylist {
    font-size: x-large;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  .playlistPreviewCon {
    background-color: rgba(128, 128, 128, 0.472);
    width: 350px;
    height: 300px;
    overflow: hidden;
    position: relative;
    display: flex;
    margin-top: 2rem;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    p {
      font-size: 25px;
    }
    .preview-img-size {
      width: 250px;
      // height: 300px;
      overflow: hidden;
    }
  }
  .bg-white {
    background-color: white;
  }
  .media-header {
    font-size: 20px;
  }
  .media-card {
    width: 300px;
    min-height: 50px;
    padding: 5px;
    margin: 10px;
    text-align: center;
    background-color: white;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    border: 1px solid rgba(128, 128, 128, 0.616);
  }
  .media-card-con {
    padding: 5px;
    background-color: rgba(128, 128, 128, 0.102);
    width: 350px;
    min-height: 400px;
    max-height: 400px;
    overflow: auto;
    border-radius: 10px;
  }
  .css-1h51icj-MuiAutocomplete-root
    .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    border-radius: 20px !important;
  }
}
.preview-con {
  width: 100%;
  display: flex;
  justify-content: center;
}
.playlist-preview-con {
  width: 500px;
  height: 400px;
  overflow: hidden;
  position: relative;
}
.chip-com-con {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  border-radius: 30px;
  cursor: pointer;
  p {
    margin: 0px;
    font-size: 12px;
    color: #242533;
  }
}
.purple-bg {
  background-color: #e9e8fc;
}
.active-time-range {
  background-color: #ffce53;
  border: 1px solid gray;
}
.time-range-con {
  display: flex;
  flex-wrap: wrap;

  grid-gap: 10px;
  max-height: 250px;
  overflow: auto;
}
