.media-con {
  padding: 0px 2rem;
  @media (min-width: 3000px) {
    padding: 3rem 3rem;
  }
}
.addStoreCon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  .addMedia {
    font-size: x-large;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  .preview-img {
    width: 100%;
    height: 100%;
  }
}
.add-store-modal {
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-width: 800px;
  grid-gap: 20px;
}
