@import "../../styles/variables.scss";
.custom-mui-table {
  .mui-table-with-scroll {
    max-height: 550px;
    overflow-y: scroll;
  }
  .custom-table-header {
    background-color: #f4f6f8 !important; /* Adjust the color as needed */
    .MuiTableCell-root {
      color: #424344; /* Header text color */
      font-weight: bold;
    }
  }
  .table-con-cell {
    background-color: #f4f6f8 !important;
    color: #424344 !important;
    // font-size: 16px !important;
    // padding: 7px !important;
    // line-height: normal !important;
    // border: 1px solid black !important;
  }

  .si-pagination {
    display: flex;
    justify-content: center;
    padding: 20px 0px;
  }
  .clickable {
    cursor: pointer;
    color: $primary;
  }
  .con-scroll::-webkit-scrollbar-thumb {
    background-color: #c7c9cb; /* color of the scroll thumb */
    border-radius: 10px; /* roundness of the scroll thumb */
  }

  .con-scroll::-webkit-scrollbar {
    width: 2px;
    height: 10px;
    @media only screen and (min-width: 2000) {
      display: none;
    }
  }

  .con-scroll::-webkit-scrollbar {
    width: 2px;
    height: 10px;
    @media only screen and (min-width: 2000) {
      display: none;
    }
  }
  .table-container {
    max-height: 23em;
    min-height: 5em;
    width: 100%;
    overflow: auto;
  }
  .read-more-text {
    margin: 0px;
    .show-more {
      cursor: pointer;
      text-decoration: underline;
      color: #29528c;
    }
  }
}
.custom-mui-table .body-table-cell:first-child {
  border-left: 1px solid #e2e2e2;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.custom-mui-table .body-table-cell:last-child {
  border-right: 1px solid #e2e2e2;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.custom-pagination .MuiPaginationItem-root {
  border-radius: 0; /* Remove the default circle border-radius */
}
.custom-pagination .Mui-selected {
  background-color: white !important; /* Set the background color to grey for the selected page */
  border: 1px solid #e2e2e2;
  border-radius: 2px;
  color: black !important;
}
